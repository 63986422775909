import React from 'react'
import { connect } from 'react-redux'
import CoolLoader from './coolLoader'

const ProgressBar = props => {
  return props.syncData.showProgressBar ? (
    <div className='infinite-loader'>
      <CoolLoader
        message={
          props.syncData.syncProgress > 0
            ? `Board Syncing ${props.syncData.syncProgress}% `
            : 'Board Syncing'
        }
      />
    </div>
  ) : null
}

const mapStateToProps = state => {
  return {
    syncData: state.syncBoard,
  }
}

export default connect(mapStateToProps, {})(ProgressBar)

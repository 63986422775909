import React, { useState } from 'react'
import Sidebar from '../components/sidebar'
import Topbar from '../components/topbar'
// import AiIcon from '../images/svg/AiIcon'
// import { useTranslation } from 'react-i18next'
// import AiModal from '../components/AI/AiModal'
// import { useLocation } from 'react-router-dom'
import ProgressBar from '../components/progressBar'

const Layout = props => {
  const [expanded, setExpanded] = useState(true)
  // const { t } = useTranslation();
  // const [showModal, setShowModal] = useState(false)
  const [progress] = useState(30)
  // const location = useLocation()

  const style = {
    marginLeft: '245px',
  }
  return (
    <React.Fragment>
      <div className='page-dashboard'>
        <Sidebar expanded={setExpanded} />
        <section className='dashboard-content' style={expanded ? style : null}>
          <Topbar />
          {props.children}
          {/* {location?.pathname === '/dashboard' &&
            <div className="ai-icon-wrap" onClick={() => setShowModal(true)}>
              <AiIcon /> {t('AI Assistant')}
            </div>} */}
          <ProgressBar percentage={progress} />
        </section>
        {/* <AiModal show={showModal} onHide={() => setShowModal(false)} /> */}
      </div>
    </React.Fragment>
  )
}

export default Layout
